<template>
  <div id="PollForm" v-if="events.length>0">
    <div class="card">
      <div class="card-header">
        <h3>Polls</h3>
      </div>
      <div class="card-body">
        <div class="container">
          <Dropdown v-model="selectedDescriptions"
                    :options="pollsByEvents"
                    optionLabel="poll_name"
                    placeholder="Select a Description" />
          <Dropdown v-model="selectedEvent"  style="margin-top: 10px;"
                    v-if="selectedDescriptions !== null"
                    :options="eventsByDescription" optionLabel="text"
                    placeholder="Select a Event" />
          <button type="submit" class="btn btn-success" v-on:click="vote(1)">Fair</button>
          <button type="submit" class="btn btn-danger" v-on:click="vote(2)">UnFair</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';
import { useToast } from 'vue-toastification';

export default {
  name: 'PollingForm',
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  components: {
    Dropdown,
  },
  data() {
    return {
      selectedDescriptions: null,
      selectedEvent: null,
      numOfRequests: 0,
      events: [],
      polls: [],
      pollsOptions: [],
      scores: [
        {
          text: 1,
          value: 1,
        },
        {
          text: 2,
          value: 2,
        },
        {
          text: 3,
          value: 3,
        },
        {
          text: 4,
          value: 4,
        },
        {
          text: 5,
          value: 5,
        },
      ],
    };
  },
  methods: {
    async getMatchEvents() {
      await this.axios.get(`https://${this.$store.state.ip}/get-match-event-text/${this.$route.params.matchId}`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.events = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getPollsTypes() {
      await this.axios.get(`https://${this.$store.state.ip}/get-poll-types`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.polls = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.$router.push('/:catchAll(.*)');
        });
    },
    async getPollsOptions() {
      await this.axios.get(`https://${this.$store.state.ip}/get-poll-options`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.pollsOptions = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          this.$router.push('/:catchAll(.*)');
        });
    },
    async vote(optionId) {
      if (optionId === 2) {
        this.selectedScore *= -1;
      }
      await this.axios.post(`https://${this.$store.state.ip}/poll-vote`,
        {
          poll_id: this.selectedDescriptions.poll_id,
          event_id: this.selectedEvent.event_text_id,
          match_thread_id: this.$route.params.threadId,
          match_id: this.$route.params.matchId,
          poll_option_id: optionId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${this.$store.state.token}`,
          },
        })
        .then((response) => {
          this.toast.success(response.data.Message, {
            autoHideDelay: 5000,
            closeOnClick: true,
          });
        }).catch((error) => {
          if (error.response.status === 403) {
            if (this.numOfRequests === 0) {
              this.refreshToken();
              this.vote(optionId);
              this.numOfRequests += 1;
            } else {
              this.toast.error('You Need To Be Logged In To Vote!', {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
            }
          } else {
            // check is user agent is not mozilla firefox
            // eslint-disable-next-line no-lonely-if
            if (navigator.userAgent.indexOf('Firefox') === -1) {
              this.toast.error('You Need To Be Logged In To Vote!', {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
            } else {
              this.toast.error(error.response.data.Message, {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
            }
          }
        });
    },
    refreshToken() {
      this.axios.post(`https://${this.$store.state.ip}/refresh-token`, { token: localStorage.getItem('refreshToken') }).then((response) => {
        this.$store.commit('setToken', response.data.token);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    },
  },
  created() {
    this.getMatchEvents();
    this.getPollsTypes();
    this.getPollsOptions();
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    eventsByDescription() {
      // eslint-disable-next-line max-len,vue/no-side-effects-in-computed-properties
      return this.events.filter((event) => event.type === this.selectedDescriptions.poll_type);
    },
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    pollsByEvents() {
      // return polls that have more than one event
      const fPolls = [];
      for (let i = 0; i < this.polls.length; i += 1) {
        if (this.events.filter((event) => event.type === this.polls[i].poll_type).length > 0) {
          fPolls.push(this.polls[i]);
        }
      }
      return fPolls;
    },
  },
};
</script>

<style scoped>
#PollForm {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  margin-bottom: 1em;
  padding: 1em;
}
.card{
  min-width: 22em;
  max-width: 10vw;
}
.btn-success {
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
}
.btn-danger {
  margin-top: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: lighter;
}
.card-header {
  text-align: center;
}
:deep(.p-dropdown-label) {
  font-family: 'Uni Sans Heavy', serif;
  min-width: 20em;
  max-width: 10vw;
}
</style>
