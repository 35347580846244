<template>
  <Navbar/>
  <div class="container">
    <h2>{{ threadName }}</h2>
    <div class="col-auto">
      <div class="list-group list-group-flush" id="list">
        <a class="list-group-item" id="list-editor">
          <div class="row">
            <div class="col">
              <Editor v-model="state.message"
                      editorStyle="height: 180px"
                      placeholder="What Are Your Thoughts?">
              </Editor>
              <span v-if="v$.message.$error">
              {{v$.message.$errors[0].$message}}
            </span>
              <div class="row justify-content-center">
                <button class="btn btn-primary"
                        v-on:click="savePost">
                  Post
                </button>
              </div>
            </div>
          </div>
        </a>
        <a class="list-group-item">
        </a>
        <a class="list-group-item" v-for="post in posts" :key="post">
          <div class="d-flex w-100  justify-content-between">
            <h5 class="mb">{{post.user.username}}</h5>
            <small class="text-muted">{{post.post_date}}</small>
          </div>
          <p class="mb-1 text-start">{{post.post_text}}</p>
          <div class="row row-cols-3 float-end">
            <i type="button" class="bi bi-arrow-up-circle" v-if="post.liked_status === 'like'"
               v-on:click="likePost(post)" style="color:orange !important;"></i>
            <i type="button" class="bi bi-arrow-up-circle"
               v-if="post.liked_status == null || post.liked_status === 'dislike'"
               v-on:click="likePost(post)"></i>
            <span>{{post.total_likes}}</span>
            <!-- button where once clicked its highlighted on double click normal           -->
            <i type="button" class="bi bi-arrow-down-circle" v-if="post.liked_status === 'dislike'"
               v-on:click="dislikePost(post)" style="color:#2d9ec2 !important;"></i>
            <i type="button" class="bi bi-arrow-down-circle"
               v-if="post.liked_status == null || post.liked_status === 'like'"
               v-on:click="dislikePost(post)"></i>
          </div>
        </a>
      </div>
    </div>
    <ConfirmDialog
        :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
    </ConfirmDialog>
  </div>
  <div class="sticky-bottom">
    <PollingForm/>
  </div>
</template>

<script>
import Editor from 'primevue/editor';
import ConfirmDialog from 'primevue/confirmdialog';
import { useToast } from 'vue-toastification';
import { reactive, computed } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Navbar from '@/components/NavBar.vue';
import PollingForm from '@/components/Threads/PollingForm.vue';

export default {
  name: 'MatchThread',
  setup() {
    const toast = useToast();
    const state = reactive({
      message: '',
    });
    const rules = computed(() => ({
      message: [required, minLength(1)],
    }));
    const v$ = useVuelidate(rules, state);
    return {
      toast,
      state,
      v$,
    };
  },
  components: {
    PollingForm,
    Editor,
    ConfirmDialog,
    Navbar,
  },
  data() {
    return {
      threadName: '',
      posts: [],
      upColor: '',
      downColor: '',
      postsLikedByUser: [],
      numOfRequests: 0,
      loggedin: true,
    };
  },
  methods: {
    async getThreadName() {
      await this.axios.get(`https://${this.$store.state.ip}//get-match-thread-name/${this.$route.params.threadId}`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.threadName = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getPosts() {
      await this.axios.get(`https://${this.$store.state.ip}/get-match-thread-posts/${this.$route.params.threadId}`)
        .then((response) => {
          // eslint-disable-next-line prefer-destructuring
          this.posts = response.data;
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    likePost(post) {
      if (this.loggedin === false) {
        this.toast.error('You Need To Be Logged In To Vote!', {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      } else if (post.liked_status != null) {
        if (post.liked_status === 'like') {
          // eslint-disable-next-line no-param-reassign
          post.total_likes -= 1;
          // eslint-disable-next-line no-param-reassign
          post.liked_status = undefined;
          // eslint-disable-next-line no-param-reassign
          this.updatePost(post);
        } else {
          // eslint-disable-next-line no-param-reassign
          post.total_likes += 1;
          // eslint-disable-next-line no-param-reassign
          post.liked_status = 'like';
          this.updatePost(post);
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        post.total_likes += 1;
        // eslint-disable-next-line no-param-reassign
        post.liked_status = 'like';
        this.updatePost(post);
      }
    },
    dislikePost(post) {
      if (this.loggedin === false) {
        this.toast.error('You Need To Be Logged In To Vote!', {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      } else if (post.liked_status != null) {
        if (post.liked_status === 'dislike') {
          // eslint-disable-next-line no-param-reassign
          post.total_likes += 1;
          // eslint-disable-next-line no-param-reassign
          post.liked_status = undefined;
          this.updatePost(post);
        } else {
          // eslint-disable-next-line no-param-reassign
          post.total_likes -= 1;
          // eslint-disable-next-line no-param-reassign
          post.liked_status = 'dislike';
          this.updatePost(post);
        }
      } else {
        // eslint-disable-next-line no-param-reassign
        post.total_likes -= 1;
        // eslint-disable-next-line no-param-reassign
        post.liked_status = 'dislike';
        this.updatePost(post);
      }
    },
    savePost() {
      this.v$.$validate();
      if (this.loggedin === false) {
        this.toast.error('You Need To Be Logged In To Vote!', {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      } else {
        this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Confirmation',
          icon: 'bi bi-question-diamond',
          accept: () => {
            // callback to execute when user confirms the action
            if (!this.v$.$error) {
              if (this.message !== 'What Are Your Thoughts?') {
                this.axios.post(`https://${this.$store.state.ip}/save-match-thread-post`,
                  {
                    post_text: this.stripTags(this.message),
                    match_id: this.$route.params.matchId,
                    match_thread_id: this.$route.params.threadId,
                  },
                  {
                    headers: {
                      Authorization: this.$store.state.token,
                    },
                  }).then((response) => {
                  this.getPosts();
                  this.getAllPostsLikedByUser();
                  this.addLikesToPosts();
                  this.toast.success(response.data.message, {
                    autoHideDelay: 5000,
                    closeOnClick: true,
                  });
                }).catch((error) => {
                  // eslint-disable-next-line no-console
                  console.log(error);
                  if (error.response.status === 403) {
                    if (this.$store.state.token !== 'null') {
                      this.refreshToken();
                      this.savePost();
                    } else {
                      this.toast.error('You are not logged in', {
                        autoHideDelay: 5000,
                        closeOnClick: true,
                      });
                    }
                  }
                });
              }
            }
          },
          reject: () => {
            // callback to execute when user rejects the action
          },
        });
      }
    },
    updatePost(post) {
      if (this.loggedin === false) {
        this.toast.error('You Need To Be Logged In To Vote!', {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      } else {
        this.axios.post(`https://${this.$store.state.ip}/update-match-thread-post-likes`,
          {
            match_thread_post_id: post.match_thread_post_id,
            total_likes: post.total_likes,
            liked_status: post.liked_status,
          },
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }).then(() => {
          this.getPosts();
          this.getAllPostsLikedByUser();
        }).catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
          if (error.response.status === 403) {
            if (this.$store.state.token !== 'null') {
              if (this.numOfRequests === 0) {
                this.refreshToken();
                this.updatePost();
                this.numOfRequests += 1;
              } else {
                this.toast.error('You are not logged in', {
                  autoHideDelay: 5000,
                  closeOnClick: true,
                });
              }
            } else {
              this.toast.error('You are not logged in', {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
            }
          }
        });
      }
    },
    refreshToken() {
      this.axios.post(`https://${this.$store.state.ip}/refresh-token`, { token: localStorage.getItem('refreshToken') }).then((response) => {
        this.$store.commit('setToken', response.data.token);
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
    },
    getAllPostsLikedByUser() {
      this.axios.get(`https://${this.$store.state.ip}/get-match-thread-posts-liked-by-user`,
        {
          headers: {
            Authorization: this.$store.state.token,
          },
        }).then((response) => {
        this.postsLikedByUser = response.data;
        this.addLikesToPosts();
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        if (error.response.status === 403) {
          if (this.$store.state.token !== 'null') {
            if (this.numOfRequests === 0) {
              this.refreshToken();
              this.getAllPostsLikedByUser();
              this.numOfRequests += 1;
            } else {
              this.toast.error('You are not logged in', {
                autoHideDelay: 5000,
                closeOnClick: true,
              });
            }
          }
        }
      });
    },
    addLikesToPosts() {
      for (let i = 0; i < this.postsLikedByUser.length; i += 1) {
        for (let j = 0; j < this.posts.length; j += 1) {
          if (this.postsLikedByUser[i].post_id === this.posts[j].match_thread_post_id) {
            this.posts[j].liked_status = this.postsLikedByUser[i].like_status;
          }
        }
      }
    },
    stripTags(str) {
      const div = document.createElement('div');
      div.innerHTML = str;
      return div.textContent || div.innerText || '';
    },
  },
  created() {
    if (this.$store.state.token === 'null' || this.$store.state.token === '') {
      this.loggedin = false;
    }
    this.getThreadName();
    this.getPosts();
    this.getAllPostsLikedByUser();
    this.addLikesToPosts();
  },
};
</script>

<style scoped>
.container {
  padding: 1rem;
}
.row {
  padding: 0.5em;
}
.mb-1 {
  padding-top: 1em;
}
#list-editor {
  height: 100%;
  background-color: #303030;
}
.btn-primary {
  width: 50%;
  padding: 0.5em;
}
a:hover {
  color: #ffffff;
}
.bi-arrow-up-circle:hover {
  color:orange !important;
}
.bi-arrow-down-circle:hover {
  color: #2d9ec2 !important;
}
:deep(.p-editor-content) {
  font-family: 'Uni Sans Heavy', sans-serif;
}
</style>
